<template>
  <div class="partner-details w-full">
    <h5 class="text-xl lg:text-3xl mb-4">{{ details.label }}</h5>
    <div class="partner-details-cont flex sm:flex-row flex-col w-full justify-between">
      <div class="border border-solid p-4 sm:mb-0 mb-4 w-full sm:mr-4 mr-0">
        <h5 class="text-lg lg:text-1xl mb-3">Learn more</h5>
        <div v-html="details.learnMore" class="learnMore"></div>
      </div>
      <div class="flex flex-col">
        <div class="partner-details-cont-contact border border-solid p-4 mb-4 w-full">
          <h5 class="text-base lg:text-1xl mb-3">Contacts</h5>
          <div
            class="flex"
            :class="{ 'mb-4': (i + 1) !== details.contacts.length }"
            v-for="(contact, i) in details.contacts"
            :key="i">
            <img :src="contact.icon" class="rounded-full mr-3"/>
            <div class="flex flex-col">
              <h5 class="text-base mb-2">{{ contact.name }}</h5>
              <h5 class="text-sm mb-2 text-grey">{{ contact.clinic }}</h5>
              <h5 class="text-base text-green">{{ contact.phone }} | {{ contact.email }}</h5>
            </div>
          </div>
        </div>
        <div class="partner-details-cont-benefits border border-solid p-4 w-full flex">
          <img src="/final.png" class="mr-3"/>
          <div>
            <h5 class="text-base lg:text-1xl mb-3">Fresh Exclusive benefits</h5>
            <span v-html="details.benefits" class="benefits"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    details: {
      type: Object,
      required: true,
      default: () => ({
        label: '',
        learnMore: '',
        benefits: '',
        contacts: []
      }),
    },
  },
}
</script>

<style lang="scss">
.partner-details {
  &-cont {
    .border {
      border-color: #e3ddd7;
      border-radius: 0.8rem;
    }

    ul {
      list-style: disc;
      padding: 0 20px;
    }

    ol {
      list-style: auto;
      padding: 0 20px;
    }

    &-benefits {
      background: #e7f7f2;
      border-color: #8dd8c2 !important;

      img {
        height: 24px;
        width: 24px;
      }

      .benefits {
        color: #262928;
      }
    }

    @media (min-width: 576px) {
      &-benefits,
      &-contact {
        width: 440px !important;
      }
    }
  }
}
</style>